.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  background-color: #242424;
  position: relative;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  padding-top: 50px;
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  outline: none;
  border: none;
  color: rgba(245, 245, 220, 0.359);
  background-color: transparent;
  border-radius: 30px;
  height: 20px;
  width: 20px;
  padding: 0;
}
.modal-close:hover {
  background-color: #90909057;
}
