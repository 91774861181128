table {
    width: 100%;
    border-collapse: collapse;
}
th {
    border-bottom: 1px solid #ffffff1a;
    padding: 8px;
    text-align: left;
}
td {
    padding: 8px;
    text-align: left;
    color: #b6b6b6;
    font-size: 12px;
    font-weight: 400px;
}