.navbar {
  width: 212px;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #1c1c1c;
  overflow-x: hidden;
  border-right: 1px solid #ffffff1a;
  padding: 16px;
  padding-top: 30px;
  box-sizing: border-box;
  z-index: 3;
}

.navbar-item {
  color: white;
  background-color: transparent;
  height: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  padding-top: 12px;
  padding-left: 8px;
  transition: 100ms all ease-in-out;
  cursor: pointer;
}

.navbar-item-selected {
  color: white;
  background-color: #ffffff1a;
  height: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  padding-top: 12px;
  padding-left: 8px;
  transition: 100ms all ease-in-out;
  cursor: pointer;
}

.navbar-item > p,
.navbar-item-selected > p {
  display: inline-flex;
  margin: 0;
  margin-top: 7px;
  margin-left: 4px;
  vertical-align: top;
  line-height: 1;
}
.navbar-item:hover {
  opacity: 0.9;
}

.navbar-user-container {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 16px;
  box-sizing: border-box;
}
