.content {
  margin-left: 212px;
  padding-top: 120px;
  padding-bottom: 30px;
}

.content-split-right {
  float: left;
  width: 40%;
  box-sizing: border-box;
  padding-right: 8vw;
  min-height: 100px;
  padding-left: 16px;
  padding-right: 5vw;
  padding-top: 35px;
}

.content-split-left {
  float: left;
  width: 60%;
  box-sizing: border-box;
  padding-right: 16px;
  min-height: 100px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 999px) {
  .content-split-right {
    float: left;
    width: 100%;
  }

  .content-split-left {
    float: left;
    width: 100%;
  }
}
