.grid-item {
  width: 299px;
  height: 265px;
  border: 1px solid #68656080;
  box-sizing: border-box;
  margin: 5px;
  text-align: center;
  border-radius: 16px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.host-image {
  background-color: #242424;
  height: 100px;
  width: 100px;
  color: #ffffff4a;
  text-align: center;
  border-radius: 16px;
  z-index: 1;
  font-size: 16px;
  overflow: hidden;
  margin-left: 50%;
  transform: translateX(-50%);
  line-height: 100px;
}

.arrange-button {
  width: 30px;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  border: none;
  outline: none;
  color: transparent;
  cursor: pointer;
}

.arrange-button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.064);
}
