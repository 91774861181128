.stage-item {
  border: 1px solid gray;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 6px;
  padding: 4px 16px;
}

.stage-item:hover {
  border: 1px solid white;
}
.stage-item:active {
  transform: scale(0.95);
}
