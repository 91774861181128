.accordion-title {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 0;
  user-select: none;
}
.accordion-header h4 {
  display: inline-block;
  margin: 0;
}
.accordion-description {
  margin-bottom: 20px;
  display: block;
}
.accordion-header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.accordion-header-right {
  display: flex;
  align-items: center;
}
.accordion-selected-info {
  border-right: 1px solid #282828;
  padding-right: 10px;
}
.accordion-header-right img {
  margin-left: 10px;
}
.accordion-section.open .accordion-header-right img {
  transform: rotate(180deg);
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: baseline;
}
.accordion-section {
  border-top: #282828 1px solid;
}
.accordion-section.open .accordion-content {
  max-height: 1000px;
  transition: max-height 0.3s ease-in;
}
.accordion-header button {
  color: #6a6a6a;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  opacity: 0;
  pointer-events: none;
}
.accordion-section.open .accordion-header button {
  opacity: 1;
  pointer-events: all;
}

.accordion-option {
  display: inline-block;
  padding: 8px;
  border: 1px solid #333333;
  border-radius: 8px;
  cursor: pointer;
  color: #999;
}
.accordion-option:last-child {
  margin-bottom: 20px;
}

.accordion-option.selected {
  background-color: #2b2b2b;
  color: white;
  border-color: #2b2b2b;
}
