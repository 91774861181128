.host-stage-selector {
  height: 50px;
  padding-top: 3px;
  margin-right: 26px;
  box-sizing: border-box;
  cursor: pointer;
}
.host-stage-selector:hover {
  opacity: 0.8;
}
.host-stage-selector > * {
  display: inline-block;
}

.blink-opacity {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Hide the download button by default. Only needed for Firefox. */
.download-button {
  display: none;
  text-decoration: none;
  margin-top: 20px;
}
@supports (-moz-appearance: none) {
  .download-button {
    display: block;
  }
}

.download-button button {
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
