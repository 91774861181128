.headerbar {
  height: 80px;
  width: calc(100% - 212px);
  background-color: #1c1c1c;
  overflow-x: hidden;
  border-bottom: 1px solid #ffffff1a;
  padding-left: 212px;
  position: fixed;
  z-index: 2;
}
