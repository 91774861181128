.LoginPage {
  text-align: center;
  padding-top: 35vh;
  background: radial-gradient(
    ellipse 70% 50% at center bottom,
    #bebbaf59,
    transparent
  );
  height: 100vh;
}
