body {
  margin: 0;
  padding: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1c1c;
  color: white;
  font-weight: 400;
  font-size: 14px;
}

input[type="submit"],
input[type="button"] {
  background-color: transparent;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 12px;
  border: 1px solid gray;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  font-family: "Inter";
  cursor: pointer;
  color: #9f9f9f;
}
h1 {
  font-size: 22px;
  font-weight: 600;
}
h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
small {
  color: #b6b6b6;
  font-size: 12px;
  font-weight: 400px;
}
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  background-color: #2b2b2b;
  border: 1px solid #f0ede033;
  border-radius: 8px;
  padding: 8px;
  color: white;
  font-size: 14px;
  margin-bottom: 8px;
}

hr {
  border: 0.5px solid #f0ede033;
  opacity: 0.4;
}

textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 150px;
  color: white;
  background-color: #2b2b2b;
  border: 1px solid #f0ede033;
  border-radius: 8px;
  outline: none;
  box-sizing: border-box;
  padding: 12px;
  font-family: "Inter";
}

/* Basic styling for the range input */
input[type="range"] {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  background: transparent; /* Remove default background */
}

/* Styling the track */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #ffffff3d; /* Semi-transparent white for the track */
  border-radius: 5px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  background: #ffffff3d;
  border-radius: 5px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8px;
  background: #ffffff3d;
  border-radius: 5px;
}

/* Styling the thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default styles */
  appearance: none;
  height: 20px;
  width: 10px;
  background: white;
  border-radius: 5px; /* Rounded corners for the square thumb */
  cursor: pointer; /* Pointer/hand icon */
  margin-top: -6px; /* Align with the track */
}

input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 10px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  height: 20px;
  width: 10px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
}

/* Optional: focus outline */
input[type="range"]:focus {
  outline: none; /* Removes the outline */
}
